/* eslint-disable react/no-deprecated */
import { RemixBrowser } from "@remix-run/react";
import { startTransition, StrictMode } from "react";
import { hydrate } from "react-dom";

export const entryClient = () => {
  startTransition(() => {
    // Replace with hydrateRoot and remove the eslint disable directive at the top
    // once React fixes hydration issue in 18.3
    hydrate(
      <StrictMode>
        <RemixBrowser />
      </StrictMode>,
      document
    );
  });
};

entryClient();
